import {base_url, tenls_url, tenls_region, ux_plugin_url, env} from '../config/apiConfig';
import * as _ from 'lodash';
// This allows mocking in unit tests
import * as thisModule from "./FetchService";


export const fetchFeatureFlags = () => {
    return fetch(`${base_url}/featureFlags`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
};

export const fetchTenlsFeatureFlags = (token) => {
    if (tenls_url === '') {
        return Promise.resolve({ status: 404, json: () => Promise.resolve() })
    }
    return fetch(`${_.replace(tenls_url, '%s', tenls_region)}/featureFlags?token=${token}`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
};

export const fetchOrders = (token, call2Tenls) => {
    const fetchURL = `${call2Tenls ? _.replace(tenls_url, '%s', tenls_region) : base_url}/orders?token=${token}`;
    return fetch(
        fetchURL,
        {
          method: 'GET',
        headers: {'Content-Type': 'application/json'}
        }
    );
};

export const fetchAccountNameValidation = (nameAttempt, call2tenls = false, region = 'us-ashburn-1', domainType = {}) => {
    //Get JWT toke from url query
    const token = new URLSearchParams(window.location.search).get('activationToken');
    const decoded = thisModule.jwt2Obj(token);
    const lHeaders = _.get(decoded, 'version', '') === 'V2' && call2tenls === false
        ? {'Content-Type': 'application/json', 'api-flow': 'PLC'}
        : {'Content-Type': 'application/json'};
    const tenlsURL = _.replace(tenls_url, '%s', tenls_region);
    const fetchURL = call2tenls ? `${tenlsURL}/accounts?token=${token}&cloudAccount=${nameAttempt}` : `${base_url}/validateAccountName`;
    const body = domainType && domainType.value && domainType.value === 'LightWeightDomain' ? JSON.stringify({ name: nameAttempt, domainType: domainType.value, token: token }) : JSON.stringify({name: nameAttempt, token: token});
    return fetch(
        fetchURL,
        {
            method: call2tenls ? 'GET':'POST',
            headers: lHeaders,
            body: call2tenls ? null : body
        }
    );
};

export const fetchBanner = () => {
  return fetch(
    `${base_url}/banner`,
    {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    }
  );
};

export const fetchRegions = (path, call2tenls) => {
    const clientIdParam = path.includes('?') && path.includes('=') ? `&clientId=ActivateUI` : '?clientId=ActivateUI';
    const token = new URLSearchParams(window.location.search).get('activationToken');
    const fetchURL = `${call2tenls ? _.replace(tenls_url, '%s', tenls_region) : base_url}${path}${clientIdParam}${call2tenls ? '&token=' + token : ''}`;
    return fetch(
        fetchURL,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
    });
};

export const fetchPutCreateTenancy = (token, id, homeRegion) => {
    const fetchURL = `${_.replace(tenls_url, '%s', tenls_region)}/orders?token=${token}`;
    const body = JSON.stringify({id, homeRegion, token});
    return fetch(
        fetchURL,
    {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: body
    });
}

export const fetchCreateTenancy = (token, body, call2tenls, homeRegion ) => {
    const decoded = thisModule.jwt2Obj(token);
    const version = _.get(decoded, 'version', '');
    const lHeaders = version === 'V2' && call2tenls === false
                ? {'Content-Type': 'application/json', 'api-flow': 'PLC'}
                : {'Content-Type': 'application/json'};
    const fetchURL = `${call2tenls ? _.replace(tenls_url, '%s', ['dev', 'uat', 'uat2'].includes(env) ? tenls_region : homeRegion) : base_url}/tenancies?token=${token}`

  return fetch(
    fetchURL,
    {
      method: 'POST',
      headers: lHeaders,
      body: body
    }
  );
};

export const fetchAsset = (path) => {
    return fetch(
        `${ux_plugin_url}${path}`,
        { method: 'GET' }
    );
};

export const fetchStateId = (country) => fetch(
    `${base_url}/states?countryId=${country}`,
    {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    },
);

export const jwt2Obj = (token) => {
    let result;
    try {
        result = JSON.parse(Buffer.from(_.split(token, '.')[1], 'base64').toString());
    } catch {
        result = null;
    }
    return result;
};

export const getFeatureFlag = (featureName, featureFlags) => {
    if (!_.isEmpty(featureFlags)) {
        const index = _.findIndex(featureFlags, (o) => o.featureName === featureName);
        return index >= 0 ? featureFlags[index].isActive : false;
    }

    return false;
};

export const isInAllowedListedTokens = (token) => {
    const list = ['eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlcklkIjoib2NpZDEuYWNjb3VudHN1YnNjcmlwdGlvbi5vYzEuLnBpbnRsYWIuYWFhYWFhYWFjYmhpM29hcWlyZTd2anQ2aW1kcXpsc3A2ZGZtYzZjeGxjem93anc1dXQyM3gzbjZ2amphIiwiaXNzIjoiVExTIiwiaWF0IjoxNzI0NzEyNzkxfQ.K03YDivZCw29S-lc7CDDHAaJ1H6XoGnYIq3ONh0d-mzKMxEgmfK_577y_5dpY7T3n2YzDYcVoaEm7TuphNaG4966eY37uH3YLJuc2Dy3LG3h2_eFa-ssl0236g2PLzuNdJst7xcivYGkINlXpcak5iONkT6aUlqbt2smW8LajtoRtl9kvSLbeuuR_w6TejcKADoQc8aF3NAZQ__IjsUEXUmxdoJWiXtdfNpu78pA1TfUBrgyhRIiBk6-owM5pVTj0rlYUHW-mUGNRWlbLD33V2tef7WzJfOwuq5o2FnV5ikW3BSODaNwHJpQ7arWPlemIDaV1tA7O_MTlgXLcVBpKOp9uoiYk1Mnk4_F2TvCkyWbCTZ7V_OQLA4hf3MlskV3pkKZBws6pqU20F56NoijYFE031wni93Kz1jgfRmzaIr-VBe9d5wzfjhdDX7kY6uVUYLs516TjrSNMZxkBeBCccjux3L0WSJqGGI1oMO27j-rnl_Pr1esPl97QNeHt_O1w7nmRkQZY8cywQvxjpBtTGQaavCwGN__f5tga42fBc5VvACTCiHY3dN0XPVJFKpaxqQt9Gt2buE30t1pVpDNWI7duo0CLEHBuwyiF0cxvf69PljCwVdJ6KoRBrsxqEWIpRutLM9pYRDi61mmlZJQQZqeXKEXVh0u_EszEFYKvu8',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlcklkIjoib2NpZDEuYWNjb3VudHN1YnNjcmlwdGlvbi5vYzEuLnBpbnRsYWIuYWFhYWFhYWFnamk3bTQzaG95eHl6MmJ5M2t1NG91NDU3ZjdjZHhtaWtsem5ndmMyd2Y1NTdlZjJueWFxIiwiaXNzIjoiVExTIiwiaWF0IjoxNzI0NzE3OTIxfQ.IW16xq-LoZRDlkusdD2Q4nVVKOu6Jm0sy7yIjXw-3Ay8GI0ValRbnDqqnowX271sMH9zn9wq2JGw7jHVHU6-3dJOHE-AMiZ8bybCbDZJmtjO-A57GcMUBu6EUYVauA59yF26UH-3C2J9bjQd5ibaxIh_xtmw4vO03_TdiyFUSB8xHkE8_qdCDQOW6MgFjIRKKH06Tw_PV8VYn7wO_wr-U9qRTxIEvEOzHSpJ9PaM43orWYEFnAnAM-321ZQd32EqwAIqwWeYwGm0is2F5Sk_VXxFLwpr6TKTMAVQsJAOqBs1Xbv_vWAIjgWFxJPJ3ZricORWeYWnunnSDPKb0Zc08mhYoLAtT1gbiEPx9asgCuLaXDsvcnCzc8P93OkjGAUIi9bMWQjjCGr9OvqjDbq1wR2zV9SDwoGovnLwEabu9t3j-OB_1glrnfwYL-Is7DYGZuztu4YTgMWFB6cnIq8bCdF-SbSnzN_n4GTPLJc1O_Iblu8xpaL1OfEtwTfd_ThNYIOognts7nU6lWC6H7Ars5QWIbW8iQQLeKOlUavhdU4wv0_AV2FyWyre6YgSzKyqFTEMy9giJrh6HucD2GelVJtxFKnvwqY7bVeewNDfDLiAYfUWzZJPmJDi6EItEFw42mxYgJObQBnU3KXD3ogsQ-yLujHPMVQmYxgpQ1y_5Ig',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlcklkIjoib2NpZDEuYWNjb3VudHN1YnNjcmlwdGlvbi5vYzEuLnBpbnRsYWIuYWFhYWFhYWF2aDZ3dGZheXg0bHpka2wzbnBuM21tYTIyc3Bwa2UybnBrc3BxdmVlb25penBlN2x2bXBxIiwiaXNzIjoiVExTIiwiaWF0IjoxNzI0NzIyNTE1fQ.WT1XNYyz3OZyywOGc89Nsa7teqx49YqBZaSeiL18wZfhOrYXVnK1ZTid1gOuQTboVEUfbBp0TGTbdEXyZncZhGy5jndO8-Ab8mjX5ivLIBUjqeiYZqOeJZEOgx71HB7QGrl5KR1MRbS4A6n2BTbMMxoXRQozfI396gE9z1Y1eaxPzKBEA1TBG-PyGmLNgtNwyC-IwwOiykNO5IhmjUce5A79UwKhjcBb6sPgs1y8-O9OA6et41jOekHY4HkU7RnmODg2NHdwk-HkFckVtH4U_wSJlROEJyoH9IZEUblFr8r1yMoEY0Ageoy8kkkCTJMTNkFYnjk_bDzn2jd1skLrMLW2N1jvXjeZDwmUVK-i9rqBi0YOZj8iQtfoMJPws7ohIvqQlsxO2FNw-RfjoJuVsOizhfwRcy1v_3SpkU2o9JVLpesjby7MJYz2M8DEF5oxIOQq3bnc_O_3kNAfYtd-8WlY1471BZkL-_puRIed0yNgoqMWzavA-xkmL2e7UVzbkZ6zY0Vd6N_DNq8kYQrCBoYdJ53Wr1gHeXuOvdsLmSrnDgPB4j470OHSq8iunbmaKf-N3eMUlwo__RLdpIWvVUlmGZCGkL0r2URgKLeclJUIPysdHDZ1-0TrGtj6H2-F6WvQsl_YbgjpHV6sotONp7AVcvcfgucqF1DnKMTzZ4I',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlcklkIjoib2NpZDEuYWNjb3VudHN1YnNjcmlwdGlvbi5vYzEuLnBpbnRsYWIuYWFhYWFhYWFpdGdxbW52amNwZTQ1aHFycWdsdW51YjVwbDJtaTZqNHFpczZpcmR0ejUycnhyYWN4cjJxIiwiaXNzIjoiVExTIiwiaWF0IjoxNzI0NzIyNTc3fQ.jdIy73nZ1p4adyO4PM8SlojOUyXGVydEpc5h6v9PZQ52chCbsFG7x7wbcestOj2sQNKb_B2EYz4U_LiSwAd5VhovZ95EKFSpsiV7NFuoDZSqezMQgF3eTj_CtfKbcA8g4Pbs2fVEQ9JncA_AWB9C-__BuNlVuacRbdtPAECfsKiOj3Xp-VGEeGPo8sLLaC_iA1NTvyUnHyuyyGOsYdtuPJvWxmn_HUC_ZUNzbF14eqtgK-7siXA_wK106Ix3DmG1tysV_8_Eg5M5mnqfsNMAlkcunhS-Kv9FHWb5fC7YMs3tnpXhZj78lw6qZn7Iu_WBH7_PDOzyGpV3Uv6yMijrXg029VMzITyM1MFQzB63mgVUqm8crZ4-3rwTVMr_zSgKmIVB6KblPaaMIsXv3bb1Pg4k7tEi3T_kDHahkdo1wsYouCcTuDoKj7Y_5MVC-46pbcsdetnTuXfHq02BSvSA2QhHqK0XvAXK6KIc8PBHpUVoLxXkJZgDs3ekvujFikF_Nw_dGgia3dVQ4KjX845HyTmfGIsjfXs6zXPtxqcpbvIZb1OodxOZsn26a40j4F3QIzdrO9LfOWDVtNlovAPtA0mMzA628bkX0y8dJdk_wfJWcbj073fjPF526fg3HDScZq8PPp8ulaDzJE-qeQmtPxKXILSv86-Fho7UpxbKMD8',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJyZXNvdXJjZUlkIjoib2NpZDEuc3Vic2NyaXB0aW9uYWN0aXZhdGlvbmdyb3VwLm9jMjcuLm9jcy5hYWFhYWFhYWpteGN0aGY0ZWpvd2kyNHBjaXpyeng2bGY0M3E1ejNwdG5reW9zajJxbnh1ZDd2cjJiaWEiLCJpc3MiOiJUTFMiLCJpYXQiOjE3MjQxOTI4MDQsInZlcnNpb24iOiJWMiJ9.DMWq1KS1q-5Kk8QfvMnHeMH7k0XlJ8j0D9D16QfNaBhTWGy5NdYpyaz6yDUszA9kfN7u5GPUBDm9Sqv6QhiWf4mUmXp3yW7kYPG7CgSF6SzqpKlKHZxac_aRShxw0pC-n2kkwUTj_R-8t8qvAwxNP0zPKd9-lI3ikL2vaODRrRNCN3yLzAEowaNALx4ca7D53POMgaDSPp-mfJtsQD8lDtX23g4ZvFShHtNqiQyvOb5g8rou7EbGpWrBflBEbJbH-FnPNkMwpUYZVwrBDfmH8_Kd7QjpNijni7Vszc3bG6S7gLpgqoAJVgS5W-5tMMxXwj8QUGq9LDCWARqcPHnvZkCBSDjBSMW4C_5Ut5mrJfXsD-_XUI1HXrM1gSvkZ798zDaecTNhSXJsic7Js2W3kKqigW5Jts3opj-5AZKFL6H5bwMzF6kn6_7tWpoaOSIPvMr5MXIm3VRss9vqhphdG9_1QI4jyjdLg-jvXSPrIwkP5FdxeV1LVWzjzYPJ6Nu0LH8G6uRkbpBZe051Pag03Z-yk3O8CllpXyhFbde8XGFoOb8HQQP135zkAvvKvOqGxQIz9zCCQ3ke-ofE5CqIrfaYwuwbQCk9nPnqcELKR7MAXsUVC_a6JIeAHMD9MiCEwHHn-a5s3Z-H-LUcBS8d14TYZfEvxCSWwDLiyDs09T8',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJyZXNvdXJjZUlkIjoib2NpZDEuc3Vic2NyaXB0aW9uYWN0aXZhdGlvbmdyb3VwLm9jMjcuLm9jcy5hYWFhYWFhYTVsdnFxcXlpNWJyNG81ZDNmcHM2bmx3MnJvZXFocGg2dm9mYmNna216b3A3Y3lkZGc2anEiLCJpc3MiOiJUTFMiLCJpYXQiOjE3MjQxOTI4NjQsInZlcnNpb24iOiJWMiJ9.QwtZfWgSMAxn_ZCxypuP5SEvGxfCaspDZXib7lvX_9ZQO0SvjBo4j5SBp-8rxEpfz_neLMjTCp2xWpKRH_aziwUJTYPPrp_CLGa89cuGBidzcoRAGqD7HdX5YPMXHVeJCCICFoJ2zyg_KiPb88hfj-0UTi5b83tBMV0QBjXXsid0OLVJnk6sM0VsO4k4w9sOry3Igpy2Xh1GwBd_6p7IuMhjmxz_fWFfxxFQobhj8VaL-XtgHJT3k3kJU-PvKspsoVWmVsyErigyxcuuHg2kyypKIjNj-72Jc6oVkB_ucUjMxhmrRGnTWVUBHiL_fMY5CysQnWGSY-C6rPOB8To0VTGpHxWHCFCEedSM4YseLOUv6Ce88Y6bxNQpkt6XEk3kLx5VWQNUiKPsQqEEZuzExevcjzu-uhHgioPzk7nVRirImpMrAT4nRfXpncEK7QxnQux4-nw3O_sy-o5ei0xAm7niGJ7sc8TnF45vTv5u41Rxjwcp2BOYLa-fldDG85qr0eHddz38kEFa6Gs5GYAveatqdEXsbM1Y7XBbSNvJ4kHIFqcAay4ODWJaK0gndvV_IjvOJPgrOIt2schUUGj5rne1YzIWsfEWKizXUOWrMtskCVubtl7kDQRK1Vxvyy7k9HVDzLbQ3auKueocbHFSadWUpLlz0o6Ua_c5oZIhEX0',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJyZXNvdXJjZUlkIjoib2NpZDEuc3Vic2NyaXB0aW9uYWN0aXZhdGlvbmdyb3VwLm9jMjcuLm9jcy5hYWFhYWFhYTd5ZHV1M2tkazdrNTZhZnBmemd0MmUyM201Z2RvcXI0Ymw3bXpxN202dXh6YmxqM2lzNXEiLCJpc3MiOiJUTFMiLCJpYXQiOjE3MjQxOTI4NjQsInZlcnNpb24iOiJWMiJ9.QGHfqIN4IfjbWZh1vqDXN3pdAX8htyiFj3n8i9Pn2MBmPZ_SZs2gxEDi9faMbOWaK4HgwTLadCYgo4S8DYycrteoeQHh446gzLKlkOc9iSurqQMXwibAFBF663_KMVmXM-SAOAViXhGtxX7zWHdwKdY2RwD_9ssLovHciApAErKq6_92pwlCmr2wzJwzj3j7kNOuzrpEPpvU1UpvYEICObrRcNjznvpsCAaOz0ZHGbEJmL-v6sEakAFdfU7iCrdJpxasFb51c7whVmC1yDekMAve3qfSi55Zgbo8OIdnlQS3JRICIHN8F5Fy0A1Sz0RUXSxnbsCCCeoxzgJ7nEN1tyGeywTpogU_cLZTE1lOAaVsW2SBI4nydAFKodwSbwRhL87DD46gOSKTk3QAEtn__JQzV1P7ZOgrqUMfQEurDmPnWs6FQvuYP6lQ41uzqItCzTc3rJqI8-Gzt52EL2lBSmXHii5zQunuYeXY4GorcQn31cSYNQEMOq3xCPMVZBju8MLXeMd1nFEZyACEFcvOZg3aMRGOAP4t1C7c6kwcdLJAICjPYC3TmN2oPg2CzYa_8p4TrQUsR4shaW9uceFbVJaZ4TAciL_U9MsnE5AuotxWCoVlAkrQqHu6O78O8T6NP-7Sw6m92hcglz2kc3Ib-5l2KBqasQ8DgNc8-U-NgAA',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJyZXNvdXJjZUlkIjoib2NpZDEuc3Vic2NyaXB0aW9uYWN0aXZhdGlvbmdyb3VwLm9jMjguLm9jcy5hYWFhYWFhYTV2NHpob2g2a211YzVlM2VuMm9laHV5YTZleHNlZnd6eG1ya2ZpeWR2NmJncWp6bmphbXEiLCJpc3MiOiJUTFMiLCJpYXQiOjE3MjQxOTMwMzcsInZlcnNpb24iOiJWMiJ9.fTmWXkFvNj20ujf8-s4veByhYCdW9gP4cJkFc9Mr2ZPeuh6wIQtNReGBfAk0aeOVtW1zi4AZ9ylxlYtW26vyPovObnDVHydVIbTKlsBptLrPtA-18yY58yBPoxRRduTTZHcJ9om-kJt0h3L2VHJfHKJWYTxw9crHBAF124cdEtHkB4F703sOkcA49mOtJM0hgUQQpC8U0cEjavMMUm0WDsNFHGz9jcfOA0Lb5_TSmnZwIqHMvOjKIaOdedjJ1BCKxG8SznlsFvOHot_q-W0aLlbeWIGil6aQLgpmKrecLVoow21lXydMr1wCgJVGn-JUFUsZ9g2rMuWeKsmbZUrcKIUDgRj32fx-t1wDP_LDi1cFmy6PLBuAlc64gNmzjYg_jn5YX34t3p1YtWwNf5sKyu4pvp2eTU2CEgjozdhBl_XEZ9PpBHyOrjGYPmz0VyDV2_Qm4VfwarYd2RPm1EP87f-hXhpYWs3yX9I4B_9BXV_7u9_9wR_T-l7cX9jIFQwKHFkhoE0Nim3T18QnUIjEYIbkZNizKDWqbnB_8I5G_MkIRtLXMl64ubMrNeA7sp1dhn9VP8hUSYZEeRCK7KpNKqELRgwijJzcXQgDeaCmW0iQxDMFaw2yS1C9UWkqPpFtJ_mHR-1_CN78Vbf8C_zlQ2UG3c0KFykOt0skms_LZE0',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJyZXNvdXJjZUlkIjoib2NpZDEuc3Vic2NyaXB0aW9uYWN0aXZhdGlvbmdyb3VwLm9jMjguLm9jcy5hYWFhYWFhYTZnNWM2N3N6NWpsYWtrdWlycWVldTZ2amVpcjNnanh3MmNpMnVqdzVqdTV3a2dseHRiNWEiLCJpc3MiOiJUTFMiLCJpYXQiOjE3MjQxOTMwNDUsInZlcnNpb24iOiJWMiJ9.Z1eCula87d1ea5sXgr7Gq4bgy7srIUI6GLbGqnFi_AbXJBlbBcXf9MQGzXSGSLBbgbq0Qz8a7ZO5oR0n6RQ7y8z6BO6RbXZqY6miW2sdhcycLtiS7bVFf7Uj5MK9t5W8bvFFwvlpKuS9HUaZFxYfQE2T5TAlBehCH75mpTAGbZ4z26YXocpYvo1BPy1e2jcPRXuqRjXGCPolNZ38SUDVq2Stt1H1aoYI1Vtt7xe8lc140LX97veixejedcVlHzB-mRGeSssR3psQWF-gOieMit7F5KF8hMmeCFrWiMJBOp2v3eDXNzUsg6FLAI5_dDgtp1chXqNyBflXMcGq54LXXXnupaZkjL3y8rbXSd6MkMoNZ38lGgrN6ELMtNq66mglOzgCwsuZfaTHdE_4NTWJCu2AQjs1-jvVivA7Iv7q4Y7n3CRvnnTvKYFhiL08JD3LKh6rms2j0wN95Kx7ngcuhlkeFVh4a_ikF2spco2oFmQ8_tho-zP_1MG2fHs6h3DS-0RJ-bhof6gYHf4H-Bb8pdG-6rKxgUspttRa33e6qv2EWMQsH9I9Pk54FEqC396ySQ_6ioO0AzJOXkSEWTUGCax2v_w0jOqfHmrE79jaSV6GzJzyyjvv2AApdQ-vVAVnKOFyl-Ki9T9OWh3RQ1JQP4T1Zq1NqNe9t9F7dkq0jQM',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJyZXNvdXJjZUlkIjoib2NpZDEuc3Vic2NyaXB0aW9uYWN0aXZhdGlvbmdyb3VwLm9jMjguLm9jcy5hYWFhYWFhYTN0dDducHV6a2h1eXFobmZxa3J0YWJqb3VqaGszN2pvdmZraGQzZGd4NnRqY3FzbXNqc2EiLCJpc3MiOiJUTFMiLCJpYXQiOjE3MjQxOTMxMDEsInZlcnNpb24iOiJWMiJ9.Ww45TdPK4JtAj80nH1-avZgmV7Ct0KYs8AsDtZUlWLDPCAcY5bjAC5gQ2k0X5VzmehA1ZE2jhR9zn0SWlaXPOoxvjC2E0rflSypA7MF2vSNwXTy0qFzfG9UdCRkrTPR5rmgCjz-caM8GRHBcmlNAgz4aYOH1TBjvpXRMRlj9z-yv0EmaHw6fk85Yog4Xc9HRRO4bbjj0mg9e6L07DWxwU3Nmi8aTi-hufVso-cYHvJDnJHj-83veGlZjCvHIQFIi8WXWXEa34_A9KGA-slx2Eiad2nVtkuwd6E0gepIOLGsiMhn7x8VKrwNL5dWNPQIweAexLFFENPLyeVxECJexHOx-jRFEAB2a0RidcqR7r0WcTRh1EuGljE8RNttuCCoBzJtr1CFVXQVwZzFv6S-G1WqBlzbYCDDELbjkebkuBeaTsjVM8vuZjQy5Q7QZSamiVhlFQXpyrCgKGeWt3_I8chmwLjnJpQytBEaTQLyvAA9TEXfwhIacReepJMkpO9ABvWJzpu6IDtd1m74zc1dt-9lLX8HlrD-Ux9Xcn9XteJSHjAYqkufuEhccY2zQrDBdq_8ED0D8bSdqMcs8BDwjMKvRi7MzSEkrPWEb5sYBPI_V3ah0xHXF41r3stN5Vo04d2moj15J-6lQqKS_rPx2tT3mGWKvuDijW35Q9Y4awSQ'
                 ];
    return list.includes(token);
}

