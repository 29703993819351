import React from 'react';
import '../react-redwood.css';
import './RedwoodInputText.css';
import Icon from "../Icon/Icon";

export interface RedwoodInputTextProps {
    autoComplete?: string,
    id?: string,
    disabled?: boolean,
    type: string,
    label: string,
    name: string,
    value: string,
    className?: string,
    tabIndex?: number,
    placeholder: string,
    required: boolean,
    info?: boolean,
    infoMsg?: string,
    pattern?: string,
    valid: boolean,    
    error?: string,    
    onChange?: any,
    onFocus?: any
    onBlur?: any,
    isPublicOffer?: boolean
}

const RedwoodInputText: React.FC<RedwoodInputTextProps> = ({
    valid, id, disabled, type, name, value, className, tabIndex, placeholder, required, pattern, onBlur, error, onChange, label, info, infoMsg, isPublicOffer}) => {
    const [showInfo, setShowInfo] = React.useState(false)
    const onClickHandler = () => {
        setShowInfo(true);
    }
    return (
        <div>
            <label className={`has-float-label  ${valid === false ? 'redBorder' : '' }`}>
                <input 
                    autoComplete="chrome-off"
                    id={id}
                    disabled={disabled}
                    type={type}
                    name={name}
                    value={value}
                    className={className}
                    tabIndex={tabIndex}
                    placeholder={placeholder ? placeholder : type }
                    required={required}
                    pattern={pattern}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onClickHandler}
                />
                <span className="inputName">{label}</span>
            </label>
            {showInfo &&
                <>
                    <div className={valid === false ? "" : "hide"}>
                        <Icon name="error"></Icon>
                        {error}
                    </div>
                    <div className={info === true ? "" : "hide"}>
                        <Icon name="info" />
                        {infoMsg}
                    </div>
                </>
            }
            {!required &&
                <>
                    <div className="optional">Optional</div>
                    <div className="clear"/>
                </>
            }
            {required && isPublicOffer &&
                <>
                    <div className="requiredLabel">Required</div>
                    <div className="clear"></div>
                </>
            }
            <div className="clear"/>
        </div>
    );
}

export default RedwoodInputText;
