
const hostname = window.location.hostname;
const windowURL = new URLSearchParams(window.location.search);
const theme = windowURL.get('theme');

export const themeOn = hostname.toLowerCase().includes('oc17')
                    || hostname.toLowerCase().includes('psn-pco.it')
                    || hostname.toLowerCase().includes('nricloud.jp')
                    || hostname.toLowerCase().includes('activate-oc26')
                    || hostname.toLowerCase().includes('activate-oc27')
                    || hostname.toLowerCase().includes('activate-oc28')
                    || hostname.toLowerCase().includes('sovereigncloud.nz')
                    || (theme && theme.length > 0);

export const base_url = (hostname === 'localhost' && process.env.HOSTNAME !== 'PINTLAB') ? "http://localhost:24000/20200828"
                      : hostname === 'activate-stage.oraclecloud.com' ? "https://signup-api-stage.oraclecloud.com:8443/20200828"
                      : hostname === 'activate-miglab.oraclecloud.com' ? "https://signup-api-stage.oraclecloud.com:7443/20200828"
                      : hostname === 'activate.oraclecloud.com' ? "https://signup-api.oraclecloud.com/20200828"
                      : hostname === 'activate-pool2.oraclecloud.com' ? "https://signup-api-stage.oraclecloud.com:9443/20200828"
                      : (hostname === 'activate-pintlab.oraclecloud.com' || (hostname === 'activate-localhost.oraclecloud.com' && process.env.REACT_APP_ENV === 'PINTLAB')) ? "https://signup-api-stage.oraclecloud.com:443/20200828"
                      : hostname === 'activate.cloud.oracle.com' ? "https://signup-api.oraclecloud.com/20200828"
                      : hostname === 'activate-pintlab2.oraclecloud.com' ? "https://signup-api-stage.oraclecloud.com:444/20200828"
                      : (hostname.toLowerCase().includes('oc17') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'OC17')) ? "https://signup.us-dcc-phoenix-1.oci.oraclecloud17.com/20200828"
                      : hostname.toLowerCase().includes('psn-pco.it') ? "https://signup.eu-dcc-rome-1.oci.psn-pco.it/20200828"
                      : hostname.toLowerCase().includes('nricloud.jp') ? "https://signup.ap-dcc-tokyo-1.oci.nricloud.jp/20200828"
                      : hostname.toLowerCase().includes('activate-oc26') ? "https://signup.me-abudhabi-3.oci.oraclecloud26.com/20200828"
                      : (hostname.toLowerCase().includes('activate-oc27') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'OC27')) ? "https://signup.us-dcc-swjordan-1.oci.oraclecloud27.com/20200828"
                      : (hostname.toLowerCase().includes('activate-oc28')  || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'OC28')) ? "https://signup.us-dcc-swjordan-2.oci.oraclecloud28.com/20200828"
                      : hostname.toLowerCase().includes('activate-oc29') ? "https://signup.me-abudhabi-2.oci.oraclecloud29.com/20200828"
                      : hostname.toLowerCase().includes('sovereigncloud.nz') ? "https://signup.ap-hobsonville-1.oci.sovereigncloud.nz/20200828" : "";

export const tenls_url = (hostname.toLowerCase().includes('activate-pintlab') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'PINTLAB')) ?  "https://alp.tenancylifecycleservice.%s.oci.oracleiaas.com/20200808/activation"
                      : (hostname.toLowerCase().includes('activate-pintlab2') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'PINTLAB2')) ?  "https://alp.tenancylifecycleservice.%s.oci.oracleiaas.com/20200808/activation"
                      : (hostname.toLowerCase().includes('activate.oraclecloud.com') || hostname.toLowerCase().includes('activate.cloud.oracle.com')) ? "https://alp.tenancylifecycleservice.%s.oci.oracleiaas.com/20200808/activation"
                      : (hostname.toLowerCase().includes('oc17') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'OC17'))? "https://tenancylifecycleservice.%s.oci.oraclecloud17.com/20200808/activation"
                      : hostname.toLowerCase().includes('oc26') ? "https://tenancylifecycleservice.%s.oci.oraclecloud26.com/20200808/activation"
                      : (hostname.toLowerCase().includes('oc27') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'OC27'))? "https://tenancylifecycleservice.%s.oci.oraclecloud27.com/20200808/activation"
                      : (hostname.toLowerCase().includes('oc28') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'OC28'))? "https://tenancylifecycleservice.%s.oci.oraclecloud28.com/20200808/activation"
                      : hostname.toLowerCase().includes('oc29') ? "https://tenancylifecycleservice.%s.oci.oraclecloud29.com/20200808/activation"
                      : (hostname.toLowerCase().includes('localhost') && process.env.HOSTNAME !== 'PINTLAB') ? "https://alp.tenancylifecycleservice.%s.oci.oracleiaas.com/20200808/activation" : "";

export const tenls_region = (hostname.toLowerCase().includes('activate-pintlab') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'PINTLAB')) ?  "r1"
    : (hostname.toLowerCase().includes('activate-pintlab2') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'PINTLAB2')) ?  "r1"
        : (hostname.toLowerCase().includes('activate.oraclecloud.com') || hostname.toLowerCase().includes('activate.cloud.oracle.com')) ? "us-ashburn-1"
            : (hostname.toLowerCase().includes('oc17') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'OC17'))? "us-dcc-phoenix-1"
                : hostname.toLowerCase().includes('oc26') ? "me-abudhabi-3"
                    : (hostname.toLowerCase().includes('oc27') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'OC27'))? "us-dcc-swjordan-1"
                        : (hostname.toLowerCase().includes('oc28') || (hostname.toLowerCase().includes('activate-localhost') && process.env.REACT_APP_ENV  === 'OC28'))? "us-dcc-swjordan-2"
                            : hostname.toUpperCase().includes('oc29') ? "me-abudhabi-2"
                                : (hostname.toLowerCase().includes('localhost') && process.env.HOSTNAME !== 'PINTLAB') ? "r1" : "";

export const env = (hostname === 'localhost' && process.env.HOSTNAME !== 'PINTLAB') ? "dev"
                      : hostname === 'activate-stage.oraclecloud.com' ? "stg"
                      : hostname === 'activate-miglab.oraclecloud.com' ? "uat"
                      : hostname === 'activate-pool2.oraclecloud.com' ? "uat3"
                      : (hostname === 'activate-pintlab.oraclecloud.com' || (hostname === 'activate-localhost.oraclecloud.com' && process.env.REACT_APP_ENV === 'PINTLAB')) ? "uat"
                      : hostname === 'activate.oraclecloud.com' ? "prd"
                      : hostname === 'activate.cloud.oracle.com' ? "prd"
                      : hostname === 'activate-pintlab2.oraclecloud.com' ? "uat2"
                      : hostname.toLowerCase().includes('oc17') ? "oc17"
                      : hostname.toLowerCase().includes('psn-pco.it') ? "oc22"
                      : hostname.toLowerCase().includes('nricloud.jp') ? "oc25"
                      : hostname.toLowerCase().includes('activate-oc26') ? "oc26"
                      : hostname.toLowerCase().includes('activate-oc27') ? "oc27"
                      : hostname.toLowerCase().includes('activate-oc28') ? "oc28"
                      : hostname.toLowerCase().includes('activate-oc29') ? "oc29"
                      : hostname.toLowerCase().includes('sovereigncloud.nz') ? "oc31" : "";

export const signup_environment  = (hostname === 'localhost' && process.env.HOSTNAME !== 'PINTLAB') ? "dev"
                      : hostname === 'activate-stage.oraclecloud.com' ? "stage"
                      : hostname === 'activate-miglab.oraclecloud.com' ? "miglab"
                      : hostname === 'activate-pool2.oraclecloud.com' ? "pool2"
                      : (hostname === 'activate-pintlab.oraclecloud.com' || (hostname === 'activate-localhost.oraclecloud.com' && process.env.REACT_APP_ENV === 'PINTLAB')) ? "pintlab"
                      : hostname === 'activate.oraclecloud.com' ? "prd"
                      : hostname === 'activate.cloud.oracle.com' ? "prd"
                      : hostname === 'activate-pintlab2.oraclecloud.com' ? "pintlab2"
                      : hostname.toLowerCase().includes('oc17') ? "prd"
                      : hostname.toLowerCase().includes('psn-pco.it') ? "prd"
                      : hostname.toLowerCase().includes('nricloud.jp') ? "prd"
                      : hostname.toLowerCase().includes('activate-oc26') ? "prd"
                      : hostname.toLowerCase().includes('activate-oc27') ? "prd"
                      : hostname.toLowerCase().includes('activate-oc28') ? "prd"
                      : hostname.toLowerCase().includes('activate-oc29') ? "prd"
                      : hostname.toLowerCase().includes('sovereigncloud.nz') ? "prd" : "";

export const courier_service_url = (hostname === 'localhost' && process.env.HOSTNAME !== 'PINTLAB') ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : hostname === 'activate-stage.oraclecloud.com' ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : hostname === 'activate-miglab.oraclecloud.com' ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : hostname === 'activate-pool2.oraclecloud.com' ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : (hostname === 'activate-pintlab.oraclecloud.com' || (hostname === 'activate-localhost.oraclecloud.com' && process.env.REACT_APP_ENV === 'PINTLAB')) ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : (hostname === 'activate-pintlab2.oraclecloud.com' || (hostname === 'activate-pintlab2.oraclecloud.com' && process.env.REACT_APP_ENV === 'PINTLAB2')) ? 'https://courier-service-stage.oraclecloud.com/20200828'
                      : hostname === 'activate.oraclecloud.com' ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname === 'activate.cloud.oracle.com' ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname.toLowerCase().includes('oc17') ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname.toLowerCase().includes('psn-pco.it') ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname.toLowerCase().includes('nricloud.jp') ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname.toLowerCase().includes('activate-oc26') ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname.toLowerCase().includes('activate-oc27') ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname.toLowerCase().includes('activate-oc28') ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname.toLowerCase().includes('activate-oc29') ? 'https://courier-service.oraclecloud.com/20200828'
                      : hostname.toLowerCase().includes('sovereigncloud.nz') ? 'https://courier-service.oraclecloud.com/20200828' : '';

export const ux_plugin_url = (hostname.includes('localhost') || hostname.includes('pintlab')) && themeOn ? "https://console.r1.oracleiaas.com/plugins/ux-config/"
                      : hostname.includes('stage') && themeOn ? "https://cloud-staging.oracle.com/plugins/ux-config-staging/"
                      : hostname === 'activate.cloud.oracle.com' && themeOn? "https://cloud.oracle.com/plugins/ux-config/"
                      : hostname.toLowerCase().includes('oc17') ? "https://oc17.cloud.oracle.com/plugins/ux-config/console-home/"
                      : hostname.toLowerCase().includes('psn-pco.it') ? "https://cloud.psn-pco.it/plugins/ux-config/console-home/"
                      : hostname.toLowerCase().includes('nricloud.jp') ? "https://cloud.nricloud.jp/plugins/ux-config/console-home/"
                      : hostname.toLowerCase().includes('activate-oc26') ? "https://oc26.cloud.oracle.com/plugins/ux-config/console-home/"
                      : hostname.toLowerCase().includes('activate-oc27') ? "https://oc27.cloud.oracle.com/plugins/ux-config/console-home/"
                      : hostname.toLowerCase().includes('activate-oc28') ? "https://oc28.cloud.oracle.com/plugins/ux-config/console-home/"
                      : hostname.toLowerCase().includes('sovereigncloud.nz') ? "https://cloud.sovereigncloud.nz/plugins/ux-config/console-home/" : "";